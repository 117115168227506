import axios from 'axios'
import router from "@/router";
import { Message } from 'element-ui';

const request = axios.create({
    baseURL: 'https://discord.com/api/v9/',
    timeout: 50000    //前后端响应超时时间
})


//接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        //兼容服务端返回的字符串数据
        return response;
    },
    error => {
        if(error){
            if(error.response){
                let response = error.response;
                if(response.status >= 400 && response.status < 500){
                    if(response.status === 401){
                        Message.error("token失效")
                    }else if(response.status === 429){
                        Message.error("请求太多")
                    }else {
                        Message.error("资源不存在，请稍后重试")
                    }
                }else if(response.status >= 500){
                    Message.error("服务异常，请稍后重试")
                }
                if(response.data.message === 'Network Error'){
                    Message.error("网络异常，请稍后重试")
                }
            }
        }
        return Promise.reject(error)
    }
)

export default request
