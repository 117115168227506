import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAMap from 'vue-amap'
import axios from "axios";
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'cf1227ffeea8f1bfff96cceaaef4443e',
  plugin: ['AMap.Geolocation']
})

// axios.defaults.baseURL ='http://127.0.0.1:8080'
axios.defaults.baseURL ='http://discord.pg-code-go.com'

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
