<template>
	<div class="home">
		<div>
			<h1>Discord机器人管理</h1>
		</div>
		<div class="login">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-input v-model="userGuild" placeholder="请输入服务器号"/>
				</el-col>
				<el-col :span="6">
					<el-input v-model="userChannel" placeholder="请输入频道号"/>
				</el-col>
				<el-col :span="6">
					<el-input v-model="token" placeholder="请输入token"/>
				</el-col>
				<el-col :span="6">
					<el-button type="primary" @click="save" style="width: 10vw;">保存</el-button>
				</el-col>
			</el-row>
		</div>
		<div class="view">
			<div style="width: 50%;">
				<div><h2>删频道白名单</h2>
					<el-button type="warning" size="small" @click="cleanGuilds">清空白名单</el-button>
				</div>
				<el-transfer
						@change="guildChange"
						style="text-align: left; display: inline-block"
						:titles="['服务器列表', '白名单列表']"
						filterable
						:filter-method="filterMethod"
						filter-placeholder="请输入服务器id"
						v-model="guildValue"
						:data="guildData">
				</el-transfer>
			</div>
			<div style="width: 50%;">
				<div><h2>删图白名单</h2>
					<el-button type="warning" size="small" @click="cleanChannel">清空白名单</el-button>
				</div>
				<el-transfer
						@change="channelChange"
						style="text-align: left; display: inline-block"
						:titles="['服务器列表', '白名单列表']"
						filterable
						:filter-method="filterMethod"
						filter-placeholder="请输入服务器id"
						v-model="channelValue"
						:data="guildData">
				</el-transfer>
			</div>
		</div>
		<div class="operation">
			<div class="channelOrder">
				<div class="title">
					<span>频道白名单区</span>
					<el-popover
							placement="top-start"
							title="温馨提示"
							width="200"
							trigger="hover"
							content="删频道白名单的用户不受下面功能的影响">
						<i slot="reference" class="el-icon-question" style="position: relative;left: 2%"></i>
					</el-popover>
				</div>
				<div class="content">
					<el-button type="primary" @click="deleteMj" :disabled="discordFlag">一键删除MJ</el-button>
					<el-button type="primary" @click="deleteNj" :disabled="discordFlag">一键删除NJ</el-button>
					<el-button type="primary" @click="deleteChannelPart" :disabled="discordFlag">一键删除频道</el-button>
					<div>
						<el-select v-model="bot" placeholder="请选择" style="width: 100px;margin: 20px 0px;">
							<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
						<el-select v-model="speakTime" placeholder="请选择" style="width: 100px;">
							<el-option
									v-for="item in chooseTime"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
						<el-button type="primary" @click="notSpeak" :disabled="discordFlag">一键禁言</el-button>
					</div>
				</div>
			</div>
			<!--当前频道-->
			<div class="currentChannel">
				<div class="title">
					<span>当前频道区</span>
					<el-popover
							placement="top-start"
							title="温馨提示"
							width="200"
							trigger="hover"
							content="下面功能只对当前绘图频道生效">
						<i slot="reference" class="el-icon-question" style="position: relative;left: 2%"></i>
					</el-popover>
				</div>
				<div class="content">
					<div>
						<el-select v-model="read_time" placeholder="间隔时间" style="width: 100px;">
							<el-option
									v-for="item in chooseReadTime"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
						<el-input v-model="read_content" placeholder="发送内容" style="width: 140px;"></el-input>
						<el-button type="primary" @click="autoRead" :disabled="discordFlag">自动读取信息</el-button>
					</div>
				</div>
			</div>
			<!--删图区-->
			<div class="imgOrder">
				<div class="title">
					<span>图片白名单区</span>
					<el-popover
							placement="top-start"
							title="温馨提示"
							width="200"
							trigger="hover"
							content="下面功能只对删图白名单用户生效">
						<i slot="reference" class="el-icon-question" style="position: relative;left: 2%"></i>
					</el-popover>
				</div>
				<div class="content">
					<el-button type="primary" @click="autoDelImg" :disabled="discordFlag">自动删除图片</el-button>
					<el-button type="primary" @click="autoClearImg" :disabled="discordFlag">一键清空图片</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import request from "@/utils/request";
import axios from "axios";

export default {
	name: 'HomeView',
	data() {
		const generateData = _ => {
			const data = [];
			const names = [];
			const ids = [];
			if (this.guilds.length > 0) {
				this.guilds.forEach((item) => {
					names.push(item.name);
					ids.push(item.id);
				})
				names.forEach((name, index) => {
					data.push({
						label: name,
						key: index,
						id: ids[index]
					});
				});
			}
			return data;
		}
		return {
			token: '',  //MTI1MDYxNTQ1MzQ3OTM0MjEwMQ.GKp6m3.dbeiCIKtHuSK5YqiGbjfM1pQT3wyZDSyRqQ7e8
			users: [],
			wUsers: [],
			mjId: '936929561302675456',
			njId: '1022952195194359889',
			bot: '936929561302675456',
			speakTime: '',
			chooseTime: [
				{
					value: '一分钟',
					label: '一分钟'
				},
				{
					value: '一小时',
					label: '一小时'
				},
				{
					value: '一天',
					label: '一天'
				},
				{
					value: '一周',
					label: '一周'
				}
			],
			imgUser: [],
			guilds: [],
			channels: [],
			pageIndex: 0,
			pageSize: 5,
			userGuild: '',
			userChannel: '',
			discordFlag: true,
			guildData: [],
			channelData: [],
			guildValue: [],
			channelValue: [],
			filterMethod(query, item) {
				return item.ids.indexOf(query) > -1;
			},
			options: [{
				value: '936929561302675456',
				label: 'MJ'
			}, {
				value: '1022952195194359889',
				label: 'NJ'
			}],
			finallyTime: '',
			read_time:180000,
			chooseReadTime: [
				{
					value: 60000,
					label: '一分钟'
				},
				{
					value: 120000,
					label: '两分钟'
				},
				{
					value: 180000,
					label: '三分钟'
				},
				{
					value: 240000,
					label: '四分钟'
				},
				{
					value: 300000,
					label: '五分钟'
				}
			],
			read_content:'Hello, World!',
			yzCode:''
		}
	},
	created() {
		this.discordFlag = JSON.parse(localStorage.getItem('discordFlag'));
		if (!this.discordFlag) {
			this.token = JSON.parse(localStorage.getItem('token'));
			this.userGuild = JSON.parse(localStorage.getItem('userGuild'));
			this.userChannel = JSON.parse(localStorage.getItem('userChannel'));
			// 获取服务器
			this.findGuild();
			//获取表单白名单数据
			if (localStorage.getItem('channelValue') !== null) {
				this.channelValue = JSON.parse(localStorage.getItem('channelValue'));
			}

			if (localStorage.getItem('guildValue') !== null) {
				this.guildValue = JSON.parse(localStorage.getItem('guildValue'));
				if (this.guildValue.length > 0) {
					this.guildValue.forEach((item) => {
						if (!this.wUsers.some((i) => {
							return i === item
						})) {
							this.wUsers.push(item);
						}
					})
				}
			}

			this.yzCode = JSON.parse(localStorage.getItem('yzCode'));
			//验证激活码
			if( this.yzCode === null || this.yzCode === ''){
				this.$router.push("/")
				return;
			}
			this.yzCodeFun();

		}
	},
	mounted() {
	},
	methods: {
		yzCodeFun() {
			axios.get("https://token.pg-code-go.com/api/token/selectByName",{
				params:{
					tokenName: this.yzCode
				}
			}).then((res)=>{
				if(res.data === 500){
					this.$router.push("/")
				}
			})
		},
		autoRead(){
			if(this.userChannel===null || this.userChannel===''){
				this.$message.error('频道为空');
				return;
			}
			this.$message.success('开始自动读取信息');
			let data = {
				content: this.read_content,
				tts: false,
				embeds: [{
					title: "Hello, Embed!",
					description: "This is an embedded message."
				}]
			}
			setInterval(()=>{
						request({
							url: 'https://discord.com/api/v9/channels/'+this.userChannel+'/messages',
							method: 'post',
							headers: {
								'Authorization': this.token
							},
							data: data
						}).then((res) => {
							if(res.status === 200){
								this.$message.success('信息发送成功');
							}
						})
			},this.read_time)
		},
		throttle(fn, delay) {

			let lastTime = 0;
			return function () {

				const nowTime = Date.now();

				if (nowTime - lastTime > delay) {

					lastTime = nowTime;

					fn.apply(this, arguments);
				}
			}
		},

		getTimesInterval() {
			let year = new Date().getFullYear(); //获取当前时间的年份
			let month = new Date().getMonth() + 1; //获取当前时间的月份
			let day = new Date().getDate(); //获取当前时间的天数
			let hours = new Date().getHours(); //获取当前时间的小时
			let minutes = new Date().getMinutes(); //获取当前时间的分数
			let seconds = new Date().getSeconds(); //获取当前时间的秒数

			//判断禁言时间
			if (this.speakTime === '一分钟') {
				minutes++
			} else if (this.speakTime === '一小时') {
				hours++
			} else if (this.speakTime === '一天') {
				day++
			} else if (this.speakTime === '一周') {
				day += 7
			}

			hours = (hours + 16) % 24

			//当小于 10 的是时候，在前面加 0
			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}
			if (day < 10) {
				day = "0" + day;
			}
			if (month < 10) {
				month = "0" + month;
			}

			//拼接格式化当前时间
			return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds + "Z";
		},
		notSpeak() {
			if(this.speakTime === ''){
				this.$message.error('请选择禁言时间！');
				return;
			}
			this.finallyTime = this.getTimesInterval();
			request({
				url: 'https://discord.com/api/v9/users/' + this.bot + '/profile',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('查询成功！');
				this.users = res.data.mutual_guilds;
				this.users.forEach((item) => {
					if (!this.wUsers.some((i) => {
						return i === item.id
					})) {
						//禁言mj
						request({
							url: 'guilds/' + item.id + '/members/' + this.bot,
							method: 'PATCH',
							headers: {
								'Authorization': this.token
							},
							data: {
								communication_disabled_until: this.finallyTime
							}
						}).then(() => {
							this.$message(item.id + '--禁言成功！');
						})
					}
				})
			})
		},
		cleanGuilds() {
			this.guildValue = [];
			localStorage.removeItem('guildValue');
		},
		cleanChannel() {
			this.channelValue = [];
			localStorage.removeItem('channelValue');
		},
		guildChange() {
			if (this.guildValue.length > 0) {
				this.guildValue.forEach((item) => {
					if (!this.wUsers.some((i) => {
						return i === item
					})) {
						this.wUsers.push(item);
					}
				})
			}
			localStorage.setItem('guildValue', JSON.stringify(this.guildValue));
		},
		channelChange() {
			localStorage.setItem('channelValue', JSON.stringify(this.channelValue));
		},
		initGuildsView() {
			// 初始化服务器
			const data = [];
			const names = [];
			const ids = [];
			if (this.guilds.length > 0) {
				this.guilds.forEach((item) => {
					names.push(item.name);
					ids.push(item.id);
				})
				names.forEach((name, index) => {
					data.push({
						label: name,
						key: ids[index],
						ids: ids[index]
					});
				});
				this.guildData = data;
			}
		},
		save() {
			if (this.token !== '' && this.userGuild !== '' && this.userChannel !== '') {
				localStorage.setItem('token', JSON.stringify(this.token));
				localStorage.setItem('userGuild', JSON.stringify(this.userGuild));
				localStorage.setItem('userChannel', JSON.stringify(this.userChannel));
				//启动discord
				this.discordFlag = false;
				localStorage.setItem('discordFlag', JSON.stringify(this.discordFlag));
				//获取服务器信息
				this.findGuild();
			} else {
				this.$message({
					message: '输入框不能为空！',
					type: 'warning'
				});
			}
		},
		//删除NJ机器人
		deleteNj() {
			request({
				url: 'https://discord.com/api/v9/users/' + this.njId + '/profile',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('查询成功！');
				this.users = res.data.mutual_guilds;
				this.users.forEach((item) => {
					if (!this.wUsers.some((i) => {
						return i === item.id
					})) {
						//删除mj
						request({
							url: 'guilds/' + item.id + '/members/' + this.njId,
							method: 'delete',
							headers: {
								'Authorization': this.token
							}
						}).then(() => {
							this.$message(item.id + '--删除成功！');
						})
					}
				})
			})
		},
		//删除MJ机器人
		deleteMj() {
			request({
				url: 'https://discord.com/api/v9/users/' + this.mjId + '/profile',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('查询成功！');
				this.users = res.data.mutual_guilds;
				this.users.forEach((item) => {
					if (!this.wUsers.some((i) => {
						return i === item.id
					})) {
						//删除mj
						request({
							url: 'guilds/' + item.id + '/members/' + this.mjId,
							method: 'delete',
							headers: {
								'Authorization': this.token
							}
						}).then(() => {
							this.$message(item.id + '--删除成功！');
						})
					}
				})
			})
		},
		//一键删除频道（总体）
		deleteChannelAll() {
			//获取服务器
			request({
				url: 'https://discord.com/api/v9/users/@me/guilds',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('服务器查询成功！');
				let guilds = res.data;
				// 获取频道
				guilds.forEach((item) => {
					if (!this.wUsers.some((i) => {
						return i === item.id
					})) {
						request({
							url: 'https://discord.com/api/v9/guilds/' + item.id + '/channels',
							method: 'get',
							headers: {
								'Authorization': this.token
							}
						}).then((res) => {
							this.$message('频道查询成功！');
							let channels = res.data;
							channels.forEach((i) => {
								if (i.name !== '语音频道') {
									request({
										url: 'https://discord.com/api/v9/channels/' + i.id,
										method: 'delete',
										headers: {
											'Authorization': this.token
										}
									}).then(() => {
										this.$message(i.id + '--删除成功！');
									})
								}
							})
						})
					}
				})
			})
		},
		//一键删除频道（分步）
		deleteChannelPart() {
			// this.findGuild();
			if (this.guildValue.length > 0) {
				this.guildValue.forEach((item) => {
					this.wUsers.push(item);
				})
			}
			if (this.wUsers.length > 0)
				this.autoDelChannel();
		},
		//获取服务器
		findGuild() {
			//获取服务器
			request({
				url: 'users/@me/guilds',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('服务器查询成功！');
				let guilds = res.data;
				this.guilds = res.data;
				//初始化名单
				this.initGuildsView();
			})
		},
		autoDelChannel() {
			setInterval(() => {
				let guilds = this.guilds.slice(this.pageIndex * (this.pageSize), this.pageSize * (this.pageIndex + 1));
				this.pageIndex++;
				if (guilds.length > 0) {
					guilds.forEach((item, index) => {
						if (!this.wUsers.some((i) => {
							return i === item.id
						})) {
							// setTimeout(()=>{
							// 	console.log(item)
							// },1000*index)
							this.findChannel(item.id);
						}
					})
				}
			}, 5000)
		},
		//获取频道
		findChannel(guildId) {
			request({
				url: 'https://discord.com/api/v9/guilds/' + guildId + '/channels',
				method: 'get',
				headers: {
					'Authorization': this.token
				}
			}).then((res) => {
				this.$message('频道查询成功！');
				let channels = res.data;
				this.channels = res.data;
				if (channels.length > 0) {
					channels.forEach((i) => {
						if (i.name !== '语音频道') {
							this.deleteOneChannel(i.id);
						}
					})
				}
			})
		},
		//删除指定频道
		deleteOneChannel(channelId) {
			request({
				url: 'https://discord.com/api/v9/channels/' + channelId,
				method: 'delete',
				headers: {
					'Authorization': this.token
				}
			}).then(() => {
				this.$message(channelId + '--删除成功！');
			})
		},

		//删除图片信息
		deleteImage() {
			this.imgUser.forEach((i) => {
				request({
					url: 'https://discord.com/api/v9/channels/' + i + '/messages',
					method: 'get',
					params: {
						limit: '5'
					},
					headers: {
						'Authorization': this.token
					}
				}).then((res) => {
					this.$message('查询成功！');
					console.log(res.data);
					let imgList = [];
					imgList = res.data;
					if (imgList.length > 0) {
						imgList.forEach((item, index) => {
							// setTimeout(() => {
							// 	console.log(item.id)
							// }, 1000 * index)
							let str = item.content;
							if (str.substring(str.indexOf('(') + 1, str.lastIndexOf(')')) === 'relaxed' || str.substring(str.indexOf('(') + 1, str.lastIndexOf(')')) === 'fast') {
								const fetchData = this.throttle(() => {
									// 发送请求
									request({
										url: 'https://discord.com/api/v9/channels/' + i + '/messages/' + item.id,
										method: 'delete',
										headers: {
											'Authorization': this.token
										}
									}).then(() => {
										this.$message(item.id + '--删除成功！');
									})
								}, 3000);
								window.addEventListener('scroll', fetchData);
							}
						})
					}
				})
			})
		},
		autoDelImg() {
			if (this.channelValue.length > 0) {
				this.channelValue.forEach((item) => {
					if (item) {
						request({
							url: 'https://discord.com/api/v9/guilds/' + item + '/channels',
							method: 'get',
							headers: {
								'Authorization': this.token
							}
						}).then((res) => {
							this.$message('频道查询成功！');
							// this.imgUser = res.data.map((item) => {
							// 	return item.id
							// })
							res.data.forEach((item) => {
								this.imgUser.push(item.id)
							})
						})
					}
				})
			}
			if (this.imgUser.length > 0) {
				const fetchData = this.throttle(() => {
					// 发送请求
					this.deleteImage();
				}, 1000);
				window.addEventListener('scroll', fetchData);
				// setInterval(() => {
				// 	this.deleteImage();
				// }, 5000)
			}
		},
		//清空图片信息
		clearImg() {
			this.imgUser.forEach((i) => {
				request({
					url: 'https://discord.com/api/v9/channels/' + i + '/messages',
					method: 'get',
					params: {
						limit: '5'
					},
					headers: {
						'Authorization': this.token
					}
				}).then((res) => {
					this.$message('查询成功！');
					console.log(res.data);
					let imgList = [];
					imgList = res.data;
					if (imgList.length > 0) {
						imgList.forEach((item) => {
							request({
								url: 'https://discord.com/api/v9/channels/' + i + '/messages/' + item.id,
								method: 'delete',
								headers: {
									'Authorization': this.token
								}
							}).then(() => {
								this.$message(item.id + '--删除成功！');
							})
						})
					}
				})
			})
		},
		autoClearImg() {
			setInterval(() => {
				this.clearImg();
			}, 5000)
		}
	}
}
</script>

<style lang="less" scoped>
.home {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.login {
	width: 100%;
	margin: 30px 0;
}

.view {
	width: 100%;
	margin: 30px 0;
	display: flex;
	justify-content: space-between;
}

.operation{
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.channelOrder,.imgOrder, .currentChannel{
	width: 29%;
	display: flex;
	flex-direction: column;
	border: 2px solid #dedede;
	border-radius: 5px;
}

.channelOrder{
	margin-left: 5%;
}

.channelOrder .title,.imgOrder .title, .currentChannel .title{
	text-align: left;
	padding: 10px;
	border-bottom: 1px solid #dedede;
}

.channelOrder .content,.imgOrder .content,.currentChannel .content{
	display: table;
	padding: 10px;
}

.imgOrder{
	margin-right: 5%;
}
</style>
